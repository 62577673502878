import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import get from 'lodash/get';

// domains
import { CreateNotifActionPayload, NotifType } from 'src/domains/notifications/types';
import { createNotification } from 'src/domains/notifications/actions';
import w from 'src/lib/window';

const mainRegexp = new RegExp(/\/main\.([^.]*)\.chunk.js/);
const runtimeRegexp = new RegExp(/\/runtime-main\.([^.]*)\.js/);

const FIVE_MINUTES = 1000 * 60 * 5;

export type Props = {
  interval: number;
  assetManifestURL: string;
  debug: boolean;
  createNotification: (payload: CreateNotifActionPayload) => void;
};

// ReactAppOutdated will check periodically if the current build
// is outdated and take actions if so
export class ReactAppOutdated extends React.Component<Props> {
  static defaultProps = {
    interval: FIVE_MINUTES,
    log: false,
    assetManifestURL: '/asset-manifest.json',
    debug: false,
  };

  private intervalID: NodeJS.Timeout | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      outdated: false,
    };

    this.intervalID = null;
  }

  componentDidMount() {
    const { interval, assetManifestURL } = this.props;
    this.log('RAO:', assetManifestURL);

    this.intervalID = setInterval(this.safeCompareBuild, interval);
  }
  componentWillUnmount() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  log = (...args: any[]) => (this.props.debug ? console.log(...args) : null);

  outdated = () => {
    this.log('RAO: outdated');

    this.props.createNotification({
      message: 'Your application is outdated, automatically reloading the app in 3 seconds',
      type: NotifType.WARNING,
      duration: 10000,
    });
    setTimeout(() => {
      w.reload();
    }, 3000);
  };

  // it's actually just calling compare build but at least it cannot fail
  safeCompareBuild = async () => {
    try {
      await this.compareBuild();
    } catch (e) {
      // warn the developer, but it could fail for many reasons: e.g. no network
      this.log('RAO: error', e);
      // eventually fail silently
    }
  };

  // compareBuild will fetch the asset-manifest.json file from the provided url
  // and extract the main & runtime version
  // Then it will compare with the current build version and then it will do
  // the following:
  //  - render a component if render function is provided. This could be an
  //    an invitation to reload the page
  //  - call a function each time it's outdated
  compareBuild = async () => {
    this.log('RAO: start compare build');
    const { assetManifestURL } = this.props;

    const response = await fetch(`${assetManifestURL}?timestamp=${moment().unix()}`);
    const manifest = await response.json();

    const runtimeBuildNumber = (get(manifest, ['files', 'runtime-main.js'], '').match(runtimeRegexp) || [])[1];
    const mainBuildNumber = (get(manifest, ['files', 'main.js'], '').match(mainRegexp) || [])[1];

    const scripts = document.querySelectorAll('script');

    // @ts-ignore
    for (const script of scripts) {
      const mainJSMatch = script.src.match(mainRegexp);
      const runtimeMatch = script.src.match(runtimeRegexp);

      this.log('RAO: mainJSMatch 1)', mainBuildNumber, (mainJSMatch || [])[1]);
      this.log('RAO: runtimeMatch 2)', runtimeBuildNumber, (runtimeMatch || [])[1]);

      if (mainBuildNumber && mainJSMatch && mainBuildNumber !== mainJSMatch[1]) return this.outdated();
      if (runtimeBuildNumber && runtimeMatch && runtimeBuildNumber !== runtimeMatch[1]) return this.outdated();
    }

    this.log('RAO: not outdated');
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = { createNotification };

// @ts-ignore
const ReactAppOutdatedConnected = connect(null, mapDispatchToProps)(ReactAppOutdated);

export default ReactAppOutdatedConnected;
