import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Event } from 'src/domains/dl/events/common';

import log from 'src/lib/utils/log';

import maplib from 'src/lib/map';

// domains
import { Vendor } from 'src/d/pandago';
import { AuthState } from 'src/domains/auth/types';
import env from 'src/domains/env';

const MAP_NAME = maplib.vendor.__NAME__ as string;

declare global {
  interface Window {
    dataLayer: any;
  }
}

const extractedData = (auth: AuthState | null, vendor?: Vendor) => {
  const d: any = {};
  const v = isEmpty(vendor) ? {} : vendor;

  if (auth) {
    const userID = get(auth, 'user.id', null);

    d.userId = userID;

    if (v) {
      const vID = get(v, 'vendor_id');
      const vName = get(v, 'vendor_information.name', '');
      const vCurrency = get(v, 'vendor_information.currency');

      const vCity = get(v, 'vendor_information.location.city', '');
      const vLat = get(v, 'vendor_information.location.latitude', '');
      const vLng = get(v, 'vendor_information.location.longitude', '');
      const vAddress = get(v, 'vendor_information.location.address_text', '');

      d.vendorCode = vID;
      d.vendorCodeUnique = `${vID}-${env.country}`;
      d.vendorName = vName;
      d.locationCity = vCity;
      d.locationLat = vLat;
      d.locationLon = vLng;
      d.locationAddress = vAddress;

      d.localCurrencyCode = vCurrency;
    }
  }

  return d;
};

const common = () => {
  const vkdefault = {
    locationCountry: env.country,
    locationCity: '',
    locationLat: '',
    locationLon: '',
    locationAddress: '',
    languageSelected: '',
    locationMethod: MAP_NAME,
    localCurrencyCode: env.currency,
  };

  const res = { ...vkdefault };
  res.languageSelected = ''; // TODO:i18nSelection.getLang() || '';

  if (!res) {
    log.error('TRACKING', `country '${env.country}' not handled`);
    return {};
  }

  return res;
};

export const push = (stuff: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(stuff);
};

export const pageView = (path: string, userID: string | undefined) => {
  const d = {
    ...common(),
    // @ts-ignore
    ...extractedData({ user: { id: userID } }),
    event: 'virtualPageView',
    pageUrlPath: path,
    userLoggedIn: !!userID,
    locationCountry: env.country,
    brand: env.dhEntity,
    platform: 'web',
    hour: new Date().toISOString(),
  };

  log.info('DL', 'pageview', path, d);

  push(d);
};

export const pushEvent = (event: Event, extra?: { [key: string]: string | number }) => {
  // merge data
  const x: { [k: string]: string | number } = {
    ...event,
  };

  if (extra) {
    Object.keys(extra).forEach(key => {
      if (extra[key] || extra[key] === 0) {
        x[key] = extra[key];
      }
    });
  }

  log.info('DL', 'eventName', event.event, x);

  push(x);
};

export default {
  push,
  pageView,
};
