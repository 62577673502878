export enum PaymentMethod {
  OnlinePayment = 'PAID',
  CashOnDelivery = 'CASH_ON_DELIVERY',
  CardOnDelivery = 'CARD_ON_DELIVERY',
}

export const isPaymentMethod = (pm: string) => Object.values(PaymentMethod).includes(pm as PaymentMethod);

export default {
  PaymentMethod,
  isPaymentMethod,
};
