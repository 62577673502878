// Google Places API Helper
//;

type Coordinates = {
  lat: number;
  lng: number;
};

export type GoogleAPI = {
  reverseGeocoding: (point: Coordinates) => Promise<string>;
};

const PLACEHODER = 'Unknown address.';

const api: GoogleAPI = {
  reverseGeocoding: (point: Coordinates) => {
    return new Promise(resolve => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: point }, results => {
        if (results && results.length > 0) {
          resolve(results[0].formatted_address || PLACEHODER);
        } else {
          resolve(PLACEHODER);
        }
      });
    });
  },
};

export default api;
