export const NEW = 'NEW';
export const RECEIVED = 'RECEIVED';
export const WAITING_FOR_TRANSPORT = 'WAITING_FOR_TRANSPORT';
export const ASSIGNED_TO_TRANSPORT = 'ASSIGNED_TO_TRANSPORT';
export const RECEIVED_BY_VENDOR = 'RECEIVED_BY_VENDOR';
export const ACCEPTED_BY_VENDOR = 'ACCEPTED_BY_VENDOR';
export const WAITING_FOR_VENDOR = 'WAITING_FOR_VENDOR';
export const ASSEMBLED = 'ASSEMBLED';
export const RECONCILED_WITH_TRANSPORT = 'RECONCILED_WITH_TRANSPORT';
export const WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP';
export const NEAR_VENDOR = 'NEAR_VENDOR';
export const DELAYED = 'DELAYED';
export const PICKED_UP = 'PICKED_UP';
export const NEAR_CUSTOMER = 'NEAR_CUSTOMER';
export const DELIVERED = 'DELIVERED';
export const CANCELLED = 'CANCELLED';

export const STATUSES_IN_ORDER = [
  NEW,
  RECEIVED,
  WAITING_FOR_VENDOR,
  RECEIVED_BY_VENDOR,
  ACCEPTED_BY_VENDOR,
  WAITING_FOR_TRANSPORT,
  ASSIGNED_TO_TRANSPORT,
  ASSEMBLED,
  RECONCILED_WITH_TRANSPORT,
  WAITING_FOR_PICKUP,
  NEAR_VENDOR,
  PICKED_UP,
  NEAR_CUSTOMER,
  DELIVERED,
];

export const cancellableSteps = [RECEIVED, WAITING_FOR_TRANSPORT];
export const activeOnes = [
  NEW,
  RECEIVED,
  WAITING_FOR_TRANSPORT,
  ASSIGNED_TO_TRANSPORT,
  RECEIVED_BY_VENDOR,
  ACCEPTED_BY_VENDOR,
  WAITING_FOR_VENDOR,
  ASSEMBLED,
  RECONCILED_WITH_TRANSPORT,
  WAITING_FOR_PICKUP,
  NEAR_VENDOR,
  PICKED_UP,
  NEAR_CUSTOMER,
  DELAYED,
];
export const oldOnes = [DELIVERED, CANCELLED];
export const whenDisplayETAOrigin = [
  ASSIGNED_TO_TRANSPORT,
  RECEIVED_BY_VENDOR,
  ACCEPTED_BY_VENDOR,
  WAITING_FOR_VENDOR,
  ASSEMBLED,
  RECONCILED_WITH_TRANSPORT,
  WAITING_FOR_PICKUP,
  NEAR_VENDOR,
];
export const whenDisplayETADestination = [PICKED_UP, NEAR_CUSTOMER];
export const whenShouldPollDataFromRider = [
  ASSIGNED_TO_TRANSPORT,
  RECEIVED_BY_VENDOR,
  ACCEPTED_BY_VENDOR,
  WAITING_FOR_VENDOR,
  ASSEMBLED,
  RECONCILED_WITH_TRANSPORT,
  WAITING_FOR_PICKUP,
  NEAR_VENDOR,
  PICKED_UP,
  NEAR_CUSTOMER,
  DELAYED,
];
export const whenShouldNotDisplayFullMap = [NEW, RECEIVED, WAITING_FOR_TRANSPORT];

export const isValid = (s: string | undefined) => STATUSES_IN_ORDER.includes(s || 'foobar') || s === CANCELLED;

export const indexes: any = {
  NEW: STATUSES_IN_ORDER.indexOf(NEW),
  RECEIVED: STATUSES_IN_ORDER.indexOf(RECEIVED),
  WAITING_FOR_TRANSPORT: STATUSES_IN_ORDER.indexOf(WAITING_FOR_TRANSPORT),
  ASSIGNED_TO_TRANSPORT: STATUSES_IN_ORDER.indexOf(ASSIGNED_TO_TRANSPORT),
  RECEIVED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(RECEIVED_BY_VENDOR),
  ACCEPTED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(ACCEPTED_BY_VENDOR),
  WAITING_FOR_VENDOR: STATUSES_IN_ORDER.indexOf(WAITING_FOR_VENDOR),
  ASSEMBLED: STATUSES_IN_ORDER.indexOf(ASSEMBLED),
  RECONCILED_WITH_TRANSPORT: STATUSES_IN_ORDER.indexOf(RECONCILED_WITH_TRANSPORT),
  WAITING_FOR_PICKUP: STATUSES_IN_ORDER.indexOf(WAITING_FOR_PICKUP),
  NEAR_VENDOR: STATUSES_IN_ORDER.indexOf(NEAR_VENDOR),
  PICKED_UP: STATUSES_IN_ORDER.indexOf(PICKED_UP),
  NEAR_CUSTOMER: STATUSES_IN_ORDER.indexOf(NEAR_CUSTOMER),
  DELIVERED: STATUSES_IN_ORDER.indexOf(DELIVERED),
  CANCELLED: -1,
};

export const getStepNumber = (s: string | undefined) => {
  if (!isValid(s) || !s) return -2;

  return indexes[s];
};

export default {
  // all statuses
  NEW,
  RECEIVED,
  WAITING_FOR_TRANSPORT,
  ASSIGNED_TO_TRANSPORT,
  RECEIVED_BY_VENDOR,
  ACCEPTED_BY_VENDOR,
  WAITING_FOR_VENDOR,
  ASSEMBLED,
  RECONCILED_WITH_TRANSPORT,
  WAITING_FOR_PICKUP,
  NEAR_VENDOR,
  PICKED_UP,
  NEAR_CUSTOMER,
  DELIVERED,
  CANCELLED,
  DELAYED,
  // other
  STATUSES_IN_ORDER,
  getStepNumber,
  isValid,
  indexes,
  cancellableSteps,
  activeOnes,
  oldOnes,
  whenShouldPollDataFromRider,
  whenDisplayETAOrigin,
  whenDisplayETADestination,
  whenShouldNotDisplayFullMap,
};
