import { all, put, takeLatest, select } from 'redux-saga/effects';

import { TrackJS } from 'trackjs';

import history from 'src/history';

// domains
import { FilterAction } from 'src/domains/filters/types';
import { OrdersAction } from 'src/domains/orders/types';
import { getSelectedVendorID } from './selectors';

const allowedRoutesForAutomaticRefreshOrders = ['/home'];

export function* refresh() {
  const vendorID = getSelectedVendorID(yield select());

  if (vendorID) {
    TrackJS.addMetadata('vendorID', vendorID);
  } else {
    TrackJS.removeMetadata('vendorID');
  }

  if (!vendorID) return;

  if (allowedRoutesForAutomaticRefreshOrders.includes(history.location.pathname)) {
    yield put({
      type: OrdersAction.FetchOrderWithFilters,
    });
  }
}

export function* refreshOrders() {
  yield all([takeLatest([FilterAction.SetVendor, FilterAction.SetActiveOrPast], refresh)]);
}

export default [refreshOrders];
