// This library was created in order to change easily from axios
// to another fetch library in the future. In addition, it will
// prevent from typing all the url over and over.

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

import get from 'lodash/get';

import { AuthState } from 'src/domains/auth/types';

axiosRetry(axios, {
  retries: 3,
  retryDelay: retryCount => retryCount * 1000,
  retryCondition: error =>
    axiosRetry.isNetworkOrIdempotentRequestError(error) || (error.response && error.response.status === 429) || false,
});

const expandConfig = (config: AxiosRequestConfig, auth?: AuthState) => {
  const h = config.headers || {};

  const AccessToken = get(auth, 'meta.AccessToken');
  const IDToken = get(auth, 'meta.IdToken');

  if (AccessToken && IDToken) {
    h.Authorization = `Bearer ${AccessToken}|${IDToken}`;
  }
  h['Content-Type'] = 'application/json';

  config.headers = h;

  return config;
};

type PandaAPI = {
  url: (endpoint: string) => string;
  get: (endpoint: string, auth?: AuthState, config?: object) => Promise<AxiosResponse<any>>;
  delete: (endpoint: string, auth?: AuthState, config?: object) => Promise<AxiosResponse<any>>;
  post: (endpoint: string, data: object, auth?: AuthState, config?: object) => Promise<AxiosResponse<any>>;
  put: (endpoint: string, data: object, auth?: AuthState, config?: object) => Promise<AxiosResponse<any>>;
};

export default (baseUrl: string) => {
  const url = (endpoint: string) => `${baseUrl}${endpoint}`;

  const api: PandaAPI = {
    url,

    get: (endpoint, auth, config = {}) => axios.get(api.url(endpoint), expandConfig(config, auth)),
    delete: (endpoint, auth, config = {}) => axios.delete(api.url(endpoint), expandConfig(config, auth)),
    post: (endpoint, data, auth, config = {}) => axios.post(api.url(endpoint), data, expandConfig(config, auth)),
    put: (endpoint, data, auth, config = {}) => axios.put(api.url(endpoint), data, expandConfig(config, auth)),
  };

  return api;
};
