// common events

import { StateUser } from 'src/domains/auth/types';
import { Vendor, Order } from 'src/d/pandago';
import env from 'src/domains/env';
import maplib from 'src/lib/map';

/* ************************************************************************* */
/* *    Event / Default                                                    * */
/* ************************************************************************* */

export type Event = {
  event: string;
};

export type EventProps = {
  lang: string;
  user?: StateUser | null;
  vendor?: Vendor | null;
  order?: Order | null;
};

/* ************************************************************************* */
/* *    Location                                                           * */
/* ************************************************************************* */

export type Location = {
  locationCity: string;
  locationLat: string;
  locationAddress: string;
  locationLon: string;
};

export const location = (v?: Vendor | null): Location => ({
  locationCity: v?.vendor_information?.location?.city || '',
  locationLat: `${v?.vendor_information?.location?.latitude || ''}`,
  locationAddress: v?.vendor_information?.location?.address_text || '',
  locationLon: `${v?.vendor_information?.location?.longitude || ''}`,
});

/* ************************************************************************* */
/* *    Country                                                            * */
/* ************************************************************************* */

export type Country = {
  locationCountry: string;
};

export const country = () => ({
  locationCountry: env.country,
});

/* ************************************************************************* */
/* *    Language                                                           * */
/* ************************************************************************* */

export type Lang = {
  languageSelected: string;
};

export const language = (lang: string) => ({
  languageSelected: lang,
});

/* ************************************************************************* */
/* *    Currency                                                           * */
/* ************************************************************************* */

export type Currency = {
  localCurrencyCode: string;
};

export const currency = () => ({
  localCurrencyCode: env.currency,
});

/* ************************************************************************* */
/* *    Page                                                               * */
/* ************************************************************************* */

export type Page = {
  pageType: string;
};

export const page = () => ({
  pageType: '',
});

/* ************************************************************************* */
/* *    Vendor                                                             * */
/* ************************************************************************* */

export type VendorInformation = {
  vendorName: string;
  vendorCode: string;
  vendorCodeUnique: string;
};

export const vendor = (v?: Vendor | null): VendorInformation => ({
  vendorName: v?.vendor_information?.name || '',
  vendorCode: v?.vendor_id || '',
  vendorCodeUnique: v ? `${v.vendor_id}-${env.country}` : '',
});

/* ************************************************************************* */
/* *    Map                                                                * */
/* ************************************************************************* */

export type Map = {
  locationMethod: string;
};

export const locationMethod = () => ({
  locationMethod: maplib.vendor.__NAME__,
});
