// @ts-nocheck

import React from 'react';
import { Helmet } from 'react-helmet';

import env from 'src/domains/env';

function TPLMapLoader() {
  if (env.whatMaps !== 'TPL') return null;

  return (
    <Helmet>
      <script
        crossorigin="anonymous"
        onload="onTPLLoad()"
        onerror="onTPLError()"
        src={`https://api.tplmaps.com/js-api-v2/assets/tplmaps.js?api_key=${env.TPLKey}`}
      ></script>
    </Helmet>
  );
}

export default TPLMapLoader;
