import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'src/polyfills/padEnd';
import 'src/polyfills/object.assign';

import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';
import createStore from './domains/store';

import errorTracker from 'src/lib/errorTracker';
import theme from 'src/style/theme';

import './common.scss';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'src/domains/env';
import history from './history';

const { store, persistor } = createStore({ websocketSaga: true });

errorTracker.init();

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/dist/locale-data/ur'); // Add locale data for ur
require('@formatjs/intl-pluralrules/dist/locale-data/fr'); // ""                  fr
require('@formatjs/intl-pluralrules/dist/locale-data/zh');
require('@formatjs/intl-pluralrules/dist/locale-data/th');

require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/dist/locale-data/ur'); // Add locale data for ur
require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
require('@formatjs/intl-relativetimeformat/dist/locale-data/th');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
