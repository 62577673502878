import React, { Suspense, lazy } from 'react';
import { useLocation, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';

import dl from 'src/lib/dataLayer';

import retry from 'src/lib/utils/retry-lazy';
import { AuthState } from 'src/domains/auth/types';
import { connect } from 'react-redux';
import { isAuthenticated, getAuthState } from 'src/domains/auth/selectors';

import { IRootState } from 'src/domains/types';

// pages
const ForgotPassword = lazy(() => retry(() => import('src/pages/ForgotPassword')));
const HomePage = lazy(() => retry(() => import('src/pages/Home')));
const NotFound = lazy(() => retry(() => import('src/pages/404NotFound')));
const Register = lazy(() => retry(() => import('src/pages/Register')));
const SignIn = lazy(() => retry(() => import('src/pages/SignIn')));
const TransactionHistory = lazy(() => retry(() => import('src/pages/TransactionHistory')));
const RequestARider = lazy(() => retry(() => import('src/pages/RequestARider/container')));
const OrderStatus = lazy(() => retry(() => import('src/pages/OrderStatus/container')));
const HomeLoggedIn = lazy(() => retry(() => import('src/pages/HomeLoggedIn/container')));

const TrackPage = ({ auth }: { auth: AuthState | null }) => {
  const location = useLocation();

  const uid = auth?.user?.id;

  React.useEffect(() => {
    dl.pageView(location.pathname, uid);
  }, [location.pathname, uid]);
  return null;
};

type OwnProps = {};
type StateProps = {
  auth: AuthState;
  isAuthenticated: boolean;
};
type RoutesProps = OwnProps & StateProps;

type RouteProps = RoutesProps & {
  component: React.ElementType;
  exact?: boolean;
  path: string;
};

type PrivateRouteProps = RouteProps & {
  auth: AuthState;
};

const privateRouteConnector = connect(
  (state: IRootState) => ({
    auth: getAuthState(state),
  }),
  null,
);
const PrivateRouteComponent = ({ component: Component, auth, ...rest }: PrivateRouteProps) => {
  let render = (p: RouteComponentProps) => <Component {...p} auth={auth} />;

  if (!rest.isAuthenticated) {
    render = () => <Redirect to="/" />;
  }

  return <Route {...rest} render={render} />;
};
const PrivateRoute = privateRouteConnector(PrivateRouteComponent);

const OnlyPublicRoute = ({ component: Component, ...rest }: RouteProps) =>
  rest.isAuthenticated ? <Redirect to="/home" /> : <Route {...rest} render={p => <Component {...p} />} />;

const Routes: React.FC<RoutesProps> = p => (
  <>
    <TrackPage auth={p.auth} />
    <Suspense
      fallback={
        <div>
          <LinearProgress color="secondary" />
        </div>
      }
    >
      <Switch>
        <OnlyPublicRoute exact path="/" component={HomePage} {...p} />
        <OnlyPublicRoute path="/forgot-password" component={ForgotPassword} {...p} />
        <OnlyPublicRoute path="/register" component={Register} {...p} />
        <OnlyPublicRoute path="/sign-in" component={SignIn} {...p} />
        <PrivateRoute exact path="/home" component={HomeLoggedIn} {...p} />
        <PrivateRoute exact path="/request-a-rider" component={RequestARider} {...p} />
        <PrivateRoute path="/order/:vendor_id/:id" component={OrderStatus} {...p} />
        <PrivateRoute path="/transaction-history" component={TransactionHistory} {...p} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </>
);

const mapStateToProps = (state: IRootState) => ({
  auth: getAuthState(state),
  isAuthenticated: isAuthenticated(state),
});

export default connect(mapStateToProps)(Routes);
