import { Map, Polygon, Marker, LatLngBounds, FitBoundsOptions } from 'leaflet';
import waitForGlobal from 'src/lib/utils/waitForGlobal';

import api from './api';

import { MapVendor } from 'src/d/pandago';

declare global {
  interface Window {
    TPLMaps: any;
  }
}

type Coordinates = [number, number];
type CoordinatesLL = { lat: number; lng: number };

// init can avoid crashes in case the TPL cdn doesnt respond and that way
// we can protect functions before they use global methods
const init = async () => {
  // main script from TPL
  await waitForGlobal('TPLMaps');
  // leaflet: the library used internally
  await waitForGlobal('L');
};

const isReady = () => window.TPLMaps && window.L;

// initMap instantiate a map in a div
const initMap = (divID = 'map-atom') => {
  const mapOptions = {
    zoom: 8,
    minZoom: 0,
    maxZoom: 18,
    divID,
  };

  const map = window.TPLMaps.map.initMap(mapOptions) as Map;

  map.zoomControl.remove();
  map.attributionControl.remove();

  return map;
};

const ex: MapVendor = {
  __NAME__: 'TPL',
  api,
  init,
  initMap,
  isReady,
  polygon: ({ points }: { points: Coordinates[] }) => {
    const options = {
      style: {
        opacity: 0,
        fillOpacity: 0.2,
        color: '#64b5f6',
        fillColor: '#64b5f6',
      },
      points: points.map(p => [p[1], p[0]]),
    };

    const polygon = window.TPLMaps.overlays.polygon(options) as Polygon;

    return polygon;
  },
  marker: (p: Marker) => window.TPLMaps.overlays.point(p),
  setZoom: (map: Map, zoomLevel: number) => map.setZoom(zoomLevel),
  latLngBounds: (p: CoordinatesLL[]) => window.L.latLngBounds(p),
  addLayerToMap: (layer: Marker | Polygon, map: Map) => (map ? map.addLayer(layer) : null),
  removeLayerFromMap: (layer: Marker | Polygon, map: Map) => (map ? map.removeLayer(layer) : null),
  fitBounds: (map: Map, bounds: LatLngBounds, options: FitBoundsOptions) => {
    if (!map) return;
    map.fitBounds(bounds, options);
  },
  flyToBounds: (map: Map, bounds: LatLngBounds, options: FitBoundsOptions) => {
    if (!map) return;
    map.flyToBounds(bounds, options);
  },
  getLayerLatLng: (layer: Marker) => (layer ? layer.getLatLng() : {}),
  setLayerDraggable: (marker: Marker, draggable = true) => {
    if (!marker || !marker.dragging) return;
    if (draggable) {
      marker.dragging.enable();
    } else {
      marker.dragging.disable();
    }
  },
};

export default ex;
