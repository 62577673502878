// state
export type StateUser = {
  id: string;
  email: string;
};

export type Tokens = {
  AccessToken: string;
  IdToken: string;
  RefreshToken: string;
  Expires: string;
};

export type AuthState = {
  user: StateUser | null;
  meta: Tokens | null;
  loading: boolean;
};

// actions
export enum AuthAction {
  login = 'loginUser',
  logout = 'logoutUser',
  loginAttempt = 'loginAttempt',
  loginSuccess = 'loginSuccess',
  loginFailure = 'loginFailure',
}

export type Credentials = {
  email: string;
  password: string;
};

// actions
/*
export type Login = {
  type: typeof AuthAction.login;
  payload: {
    user: StateUser;
    meta: any;
  };
};
*/
export type Logout = {
  type: typeof AuthAction.logout;
};
export type LoginSuccess = {
  type: typeof AuthAction.loginSuccess;
  payload: AuthServiceResponseResult;
};
export type LoginFailure = {
  type: typeof AuthAction.loginFailure;
};
export type LoginAttempt = {
  type: typeof AuthAction.loginAttempt;
};

export type Action = LoginAttempt | /* | Login */ Logout | LoginSuccess | LoginFailure;

// service response
export type AuthenticationResult = {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;

  // NewDeviceMetadata: null;

  RefreshToken: string;
  TokenType: string;
};

export type AuthServiceResponseResult = {
  VendorLoginDetails: {
    user_id: string;
    email: string;
    additional_information: {
      country: string;
      is_active: boolean;
      vendors: string[];
      created_at: number;
      updated_at: number;
      owner_tos: { timestamp: number; version: string; ip_address: string };
    };
  };
  AuthenticationResult: AuthenticationResult;

  //ChallengeName: null;
  // ChallengeParameters: {};
  // Session: null;

  ODRToken: string;
};

export type AuthServiceResponse = {
  response_code: number;
  message: string;
  result: AuthServiceResponseResult;
};
