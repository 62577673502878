import { Order } from 'src/d/pandago';
import { OrdersAction } from './types';

export const ordersFetchLoading = () => ({ type: OrdersAction.FetchLoading });
export const ordersFetchFailure = () => ({ type: OrdersAction.FetchFailure });
export const ordersFetchSuccess = () => ({ type: OrdersAction.FetchSuccess });

export const fetchSingleOrder = (vendorID: string, orderID: string) => ({
  type: OrdersAction.FetchSingleOrder,
  payload: { vendorID, orderID },
});
export const fetchActiveDeliveries = () => ({ type: OrdersAction.FetchActiveDeliveries });
export const fetchPastOrders = () => ({ type: OrdersAction.FetchPastOrders });
export const fetchOrderWithFilters = () => ({ type: OrdersAction.FetchOrderWithFilters });

export const getOrder = (order: Order) => ({ type: OrdersAction.GetOrder, payload: order });
export const listOrders = (orders: Order[]) => ({ type: OrdersAction.ListOrders, payload: orders });
