import { AuthAction } from 'src/domains/auth/types';
import { WSActionType, Action, WSState, WSStatus } from './types';

export const initialState: WSState = {
  wsStatus: WSStatus.DISCONNECTED,
  messageQueue: [],
};

const websocket = (state = initialState, action: Action) => {
  switch (action.type) {
    case WSActionType.WriteMessage:
      const messageQueue = [...state.messageQueue];

      messageQueue.push(action.payload);

      return {
        ...state,
        messageQueue,
      };
    case WSActionType.ConnectionOpened: {
      return {
        ...state,
        wsStatus: WSStatus.CONNECTED,
      };
    }
    case WSActionType.ConnectionClosed: {
      return initialState;
    }
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default websocket;
