// types
import { Logout } from 'src/domains/auth/types';
import { Vendor } from 'src/d/pandago';

// todo add create order
export enum VendorAction {
  // reducer actions
  GetVendor = 'GET_VENDOR',
  ListVendors = 'LIST_VENDORS',
  // fetch state
  FetchLoading = 'VENDOR_FETCH_LOADING',
  FetchSuccess = 'VENDOR_FETCH_SUCCESS',
  FetchFailure = 'VENDOR_FETCH_FAILURE',
  // dispatched actions
  FetchSingleVendor = 'FETCH_SINGLE_VENDOR',
  FetchAllVendors = 'FETCH_ALL_VENDORS',
}

// STATE
export type VendorState = {
  records: { [id: string]: Vendor };
  error: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  lastFetched: string | null;
};

// actions
export type GetVendor = { type: typeof VendorAction.GetVendor; payload: Vendor };
export type ListVendors = { type: typeof VendorAction.ListVendors; payload: Vendor[] };
export type FetchLoading = { type: typeof VendorAction.FetchLoading };
export type FetchSuccess = { type: typeof VendorAction.FetchSuccess };
export type FetchFailure = { type: typeof VendorAction.FetchFailure };
export type FetchSingleVendor = { type: typeof VendorAction.FetchSingleVendor; payload: string };
export type FetchAllVendors = { type: typeof VendorAction.FetchAllVendors };

export type Action =
  | GetVendor
  | ListVendors
  | FetchLoading
  | FetchSuccess
  | FetchFailure
  | FetchSingleVendor
  | FetchAllVendors
  | Logout;
