import { EventName } from './names';

import EntryPageLoaded from './entry_page.loaded';
import RegisterPageLoaded from './register_start.loaded';
import RegisterPasswordLoaded from './register_password.loaded';
import RegisterConfirmationLoaded from './register_confirmation.loaded';
import SignInStartLoaded from './signin_start.loaded';
import RequestLoaded from './request.loaded';
import RequestAddressLoaded from './request_address.loaded';
import RequestRejectedLoaded from './request_rejected.loaded';
import RequestAcceptedLoaded from './request_accepted.loaded';
import RequestDetailsLoaded from './request_details.loaded';
import RequestReviewLoaded from './request_review.loaded';
import RequestPendingLoaded from './request_pending.loaded';
import RequestConfirmationLoaded from './request_confirmation.loaded';
import RegisterDetailsSubmitted from './register_details.submitted';
import RegisterClicked from './register.clicked';
import RegisterPasswordSubmitted from './register_password.submitted';
import RegisterLoginClicked from './register_login.clicked';
import RegisterRejectedLoaded from './register_rejected.loaded';
import RegisterRejectedClicked from './register_rejected.clicked';
import SignInClicked from './signin.clicked';
import SignInDetailsSubmitted from './signin_details.submitted';
import PasswordResetClicked from './password_reset.clicked';
import PasswordResetLoaded from './password_reset.loaded';
import PasswordResetSubmitted from './password_reset.submitted';
import PasswordResetCompleted from './password_reset.completed';
import RequestStarted from './request.started';
import RequestAddressSubmitted from './request_address.submitted';
import RequestDetailsSubmitted from './request_details.submitted';
import RequestReviewSubmitted from './request_review.submitted';
import RequestReviewCancelled from './request_review.cancelled';
import ActiveDeliveryClicked from './activedelivery.clicked';
import OrderHistoryClicked from './orderhistory.clicked';

export default {
  [EventName.EntryPageLoaded]: EntryPageLoaded,
  [EventName.RegisterStartLoaded]: RegisterPageLoaded,
  [EventName.RegisterPasswordLoaded]: RegisterPasswordLoaded,
  [EventName.RegisterConfirmationLoaded]: RegisterConfirmationLoaded,
  [EventName.SignInStartLoaded]: SignInStartLoaded,
  [EventName.RequestLoaded]: RequestLoaded,
  [EventName.RequestAddressLoaded]: RequestAddressLoaded,
  [EventName.RequestRejectedLoaded]: RequestRejectedLoaded,
  [EventName.RequestAcceptedLoaded]: RequestAcceptedLoaded,
  [EventName.RequestDetailsLoaded]: RequestDetailsLoaded,
  [EventName.RequestReviewLoaded]: RequestReviewLoaded,
  [EventName.RequestPendingLoaded]: RequestPendingLoaded,
  [EventName.RequestConfirmationLoaded]: RequestConfirmationLoaded,
  [EventName.RegisterClicked]: RegisterClicked,
  [EventName.RegisterDetailsSubmitted]: RegisterDetailsSubmitted,
  [EventName.RegisterPasswordSubmitted]: RegisterPasswordSubmitted,
  [EventName.RegisterLoginClicked]: RegisterLoginClicked,
  [EventName.RegisterRejectedLoaded]: RegisterRejectedLoaded,
  [EventName.RegisterRejectedClicked]: RegisterRejectedClicked,
  [EventName.SignInClicked]: SignInClicked,
  [EventName.SignInDetailsSubmitted]: SignInDetailsSubmitted,
  [EventName.PasswordResetClicked]: PasswordResetClicked,
  [EventName.PasswordResetLoaded]: PasswordResetLoaded,
  [EventName.PasswordResetSubmitted]: PasswordResetSubmitted,
  [EventName.PasswordResetCompleted]: PasswordResetCompleted,
  [EventName.RequestStarted]: RequestStarted,
  [EventName.RequestAddressSubmitted]: RequestAddressSubmitted,
  [EventName.RequestDetailsSubmitted]: RequestDetailsSubmitted,
  [EventName.RequestReviewSubmitted]: RequestReviewSubmitted,
  [EventName.RequestReviewCancelled]: RequestReviewCancelled,
  [EventName.ActiveDeliveryClicked]: ActiveDeliveryClicked,
  [EventName.OrderHistoryClicked]: OrderHistoryClicked,
};
