import { createSelector } from 'reselect';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';

import statuses from './statuses';

import { Order } from 'src/d/pandago';
import { IRootState } from 'src/domains/types';
import { getSelectedVendorID } from 'src/domains/filters/selectors';

const getOrders = (state: IRootState) => state.orders;

export const getOrdersRecords = createSelector(getOrders, orders => orders.records);

export const getActiveOrders = createSelector(
  // retrieve orders records
  getOrdersRecords,
  // with the records by ID
  recordsByID =>
    compose(
      // filter statuses
      filter((order: Order) => statuses.activeOnes.includes(order.status)),
      values,
    )(recordsByID),
);

export const getPastOrders = createSelector(
  // retrieve orders records
  getOrdersRecords,
  // with the records by ID
  recordsByID =>
    compose(
      // filter statuses
      filter((order: Order) => statuses.oldOnes.includes(order.status)),
      values,
    )(recordsByID),
);

// getActiveOrdersSorted will return
export const getActiveOrdersSorted = createSelector(
  // retrieve orders
  getActiveOrders,
  // with active orders
  activeOrders =>
    compose(
      // sort them by date (negative value so we are getting the newers ones first)
      sortBy((order: Order) => -order.created_at),
    )(activeOrders),
);

export const getPastOrdersSorted = createSelector(
  // retrieve getPastOrdersSorted
  getPastOrders,
  // with active orders
  activeOrders =>
    compose(
      // sort them by date (negative value so we are getting the newers ones first)
      sortBy((order: Order) => -order.created_at),
    )(activeOrders),
);

export const getPastOrdersSortedOfSelectedVendor = createSelector(
  [getPastOrdersSorted, getSelectedVendorID],
  (orders, selectedVendorID) => compose(filter((order: Order) => order.vendor_id === selectedVendorID))(orders),
);

export const getActiveOrdersSortedOfSelectedVendor = createSelector(
  [getActiveOrdersSorted, getSelectedVendorID],
  (orders, selectedVendorID) => compose(filter((order: Order) => order.vendor_id === selectedVendorID))(orders),
);

export const getOrder = (id: string) => createSelector(getOrdersRecords, recordsByID => recordsByID[id] || null);
export const getOrderStatus = (id: string) => createSelector(getOrder(id), order => order?.status);

export const getOrdersAreFetching = createSelector(getOrders, orders => orders.isFetching);
export const getOrdersHaveBeenFetched = createSelector(getOrders, orders => orders.hasFetched);

// for ws fallback purposes
export const getLastTimeFetched = createSelector(getOrders, orders => orders.lastFetched);
