import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { all, put, takeLatest, select, takeEvery, delay, call } from 'redux-saga/effects';

import history from 'src/history';

// utils
import get from 'lodash/get';
import log from 'src/lib/utils/log';
// api
import orderAPI from 'src/lib/api/odr-order';
// domains
import { Order } from 'src/d/pandago';
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import { getAuthState } from 'src/domains/auth/selectors';
import { getActiveOrPast, getSelectedVendorID } from 'src/domains/filters/selectors';
import { ActiveOrPast } from 'src/domains/filters/types';
import { dlEvent } from 'src/domains/dl/actions';
import { EventName } from 'src/domains/dl/events/names';

// internal
import { OrdersAction, FetchSingleOrder, CancelOrder } from './types';
import { listOrders, ordersFetchSuccess, ordersFetchFailure, ordersFetchLoading, getOrder } from './actions';
import { getLastTimeFetched, getOrdersHaveBeenFetched, getOrderStatus } from './selectors';
import OS from './statuses';

export function* fetchOrders(filter: string) {
  const auth = getAuthState(yield select());
  const currentVendorID = getSelectedVendorID(yield select());

  const result = yield orderAPI.get(`/vendor/${currentVendorID}/orders?${filter}`, auth);
  const orders = get(result, 'data.result.data', []) as Order[];

  return orders;
}

export function* fetchActiveDeliveries() {
  // set store in loading state
  yield put(ordersFetchLoading());

  try {
    const orders = yield fetchOrders('only_active=1');

    yield put(listOrders(orders));
    yield put(ordersFetchSuccess());
  } catch (error) {
    // create some feedback for the user
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            <FormattedMessage id="error.cant-retrieve-current-orders" />
            <div>{error.message}</div>
          </>
        ),
      }),
    );
    yield put(ordersFetchFailure());
  }
}

export function* fetchOrderHistory() {
  // set store in active state
  yield put(ordersFetchLoading());

  try {
    const orders = yield fetchOrders('all=1');

    yield put(listOrders(orders));

    yield put(ordersFetchSuccess());
  } catch (error) {
    // create some feedback for the user
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            <FormattedMessage id="error.cant-retrieve-past-orders" />
            <div>{error.message}</div>
          </>
        ),
      }),
    );
    yield put(ordersFetchFailure());
  }
}

export function* fetchOrderInfo(action: FetchSingleOrder) {
  const { vendorID, orderID } = action.payload;
  const auth = getAuthState(yield select());

  try {
    const ro = yield orderAPI.get(`/order?vendorId=${vendorID}&orderId=${orderID}`, auth);
    const order = ro?.data?.result;

    yield put(getOrder(order));
  } catch (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: <FormattedMessage id="error.cant-retrieve-order" />,
        duration: 10000,
      }),
    );
  }
}

export function* fetchOrderWithFilters() {
  const activeOrPast = getActiveOrPast(yield select());

  if (activeOrPast === ActiveOrPast.Active) {
    yield call(fetchActiveDeliveries);
  }
  if (activeOrPast === ActiveOrPast.Past) {
    yield call(fetchOrderHistory);
  }
}

export function* orderListSaga() {
  yield all([
    takeLatest(OrdersAction.FetchSingleOrder, fetchOrderInfo),
    takeLatest(OrdersAction.FetchPastOrders, fetchOrderHistory),
    takeLatest(OrdersAction.FetchActiveDeliveries, fetchActiveDeliveries),
    takeLatest(OrdersAction.FetchOrderWithFilters, fetchOrderWithFilters),
  ]);
}

export function* cancelOrder(action: CancelOrder) {
  const { orderID, reason } = action.payload;
  const orderStatus = getOrderStatus(orderID)(yield select());

  // todo missing order DATA TODO XXX NOTE
  yield put(dlEvent(EventName.RequestReviewCancelled));

  try {
    const match = orderID.match(/(.*)-(.*)/);
    if (!match) throw new Error('invalid order ID');
    if (!OS.cancellableSteps.includes(orderStatus))
      throw new Error(`order cannot be cancelled at this stage: ${orderStatus}`);
    const vendorID = match[1];

    const auth = getAuthState(yield select());

    yield orderAPI.put(
      `/order/cancel`,
      {
        vendor_id: vendorID,
        order_id: orderID,
        reason,
      },
      auth,
    );

    yield put(
      createNotification({
        type: NotifType.INFO,
        message: <FormattedMessage id="action.attempt-cancel" />,
      }),
    );
  } catch (error) {
    console.log('error', error);
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: <FormattedMessage id="error.cant-cancel-order" />,
        duration: 7000,
      }),
    );
  }
}

export function* cancelOrderSaga() {
  yield takeEvery(OrdersAction.CancelOrder, cancelOrder);
}

// websocketFallback will eventually fetch all active orders again if the user didn't get any updates
// for the past minute.
export function* websocketFallback() {
  while (true) {
    const lastTimeFetched = getLastTimeFetched(yield select());
    const ordersHaveBeenFetched = getOrdersHaveBeenFetched(yield select());

    // in order to trigger the websocket fallback:
    //  - we have to fetch the orders at least once
    //  - we didn't get any updates for the past minute
    if (lastTimeFetched && ordersHaveBeenFetched) {
      const lastTimeFetchedInS = moment.duration(moment().diff(lastTimeFetched)).asSeconds();

      const pathname = history.location.pathname;

      // faster fallback on order page
      const delayInSeconds = pathname.match(/\/order\/.*/) ? 20 : 60;

      if (lastTimeFetchedInS > delayInSeconds) {
        log.info('ws-fallback', 'update active deliveries');
        yield call(fetchActiveDeliveries);
      }
    }
    yield delay(5 * 1000);
  }
}

export default [orderListSaga, cancelOrderSaga, websocketFallback];
