import vendor from './vendor';
import { DeliveryArea, DeliveryAreaGeoJSONCoordinates } from 'src/d/pandago';

type Coordinates = {
  lat: number;
  lng: number;
};

/* eslint-disable */
const isInsideAlgorithm = (point: Coordinates, vs: DeliveryAreaGeoJSONCoordinates) => {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point.lat;
  const y = point.lng;

  var inside = false;

  if (!vs || !(vs.length > 0)) {
    return false;
  }

  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][1];
    const yi = vs[i][0];

    const xj = vs[j][1];
    const yj = vs[j][0];

    const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }
  return inside;
};
const isInside = (point: Coordinates, deliveryAreas: DeliveryArea[]) => {
  for (const da of deliveryAreas) {
    for (const polygon of da.geo_json.coordinates) {
      if (isInsideAlgorithm(point, polygon)) {
        return true;
      }
    }
  }
  return false;
};
/* eslint-enable */

export default {
  isInside,
  vendor,
};
