import { AuthAction } from 'src/domains/auth/types';

import { FilterAction, FilterState, ActiveOrPast, Action } from './types';

export const initialState: FilterState = {
  vendor: null,
  activeOrPast: ActiveOrPast.Active,
};

const filters = (state = initialState, action: Action): FilterState => {
  switch (action.type) {
    case FilterAction.SetVendor:
      return {
        ...state,
        vendor: action.payload,
      };
    case FilterAction.SetActiveOrPast:
      return {
        ...state,
        activeOrPast: action.payload,
      };
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default filters;
