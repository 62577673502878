// register_confirmation.loaded

import { EventName } from './names';
import { Event, EventProps, Page, Country, Lang, country, language, currency } from './common';

export default (p: EventProps): Event & Page & Lang & Country => ({
  event: EventName.RegisterConfirmationLoaded,
  pageType: 'register',
  ...country(),
  ...language(p.lang),
  ...currency(),
});
