import { AuthState, AuthServiceResponse, Credentials } from './types';
import odrAPI from 'src/lib/api/odr';
import get from 'lodash/get';

import log from 'src/lib/utils/log';

// login service will attempt to login the user using Credentials (email & password)
const login = async (credentials: Credentials) => {
  try {
    const response = await odrAPI.post('/login', {
      email_address: credentials.email,
      password: credentials.password,
    });

    const responseData = response.data as AuthServiceResponse;

    return { result: responseData.result, error: null };
  } catch (error) {
    const status = get(error, 'response.status', 0) as number;
    let message = get(error, 'response.data.message') as string;

    if (!status) {
      message = 'Something went wrong. Please try again. You could be offline.';
    }
    return { result: null, error: { status, message } };
  }
};

// refresh token will attempt to refresh the token using the response from the initial login
// and will return the updated data (including the refresh token) or null if the refresh has failed.
const refreshToken = async (auth: AuthState) => {
  try {
    const response = await odrAPI.post(
      '/login/extend',
      {
        refresh_token: auth.meta?.RefreshToken,
      },
      auth,
    );
    const responseData = response.data as AuthServiceResponse;
    const result = responseData.result;

    if (auth.meta) {
      result.AuthenticationResult.RefreshToken = auth.meta.RefreshToken;
    }

    return result;
  } catch (error) {
    log.info('Error while refreshing token');
    console.log('error', error);
    return null;
  }
};

// expireToken will attempt to expire the tokens on the server. It will return a promise that will return true if the
// attempt succeeded or false if it failed without more precisions.
const expireToken = async (auth: AuthState) => {
  try {
    await odrAPI.post('/logout', {}, auth);
    return true;
  } catch (err) {
    return false;
  }
};

export default {
  login,
  refreshToken,
  expireToken,
};
