import React from 'react';

type RetryFunction = () => Promise<any>;

const retry = (fn: RetryFunction, retriesLeft = 10, interval = 2000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft <= 0) {
            return resolve(require('./placeholder'));
          }

          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  }) as Promise<{ default: React.ComponentType<any> }>;

export default retry;
