export enum ActionTypes {
  INIT_APP = 'INIT_APP',
  FAIL_API = 'FAIL_API',
}

export enum ClassicState {
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}
