import i18n from 'src/i18n';
import { Country, isCountry } from 'src/d/countries';
import { isPaymentMethod, PaymentMethod } from 'src/d/payment';

const appSupportedLanguages = Object.keys(i18n);

const ns = (E: string) => (f?: Function) => {
  const c = process.env[E] || '';

  if (!c) throw new Error(`${E} not set`);

  if (f instanceof Function) {
    return f(c, E);
  }
  return c;
};

const supportedLanguages = (c: string, E: string) => {
  const sl = c.split(',');

  if (sl.length === 0) {
    throw new Error(`${E} not set`);
  }

  for (const l of sl) {
    if (!appSupportedLanguages.includes(l)) {
      throw new Error(`${l} is not a supported language by PandaGO`);
    }
  }

  return sl;
};

const defaultLang = (l: string) => {
  if (!appSupportedLanguages.includes(l)) {
    throw new Error(`${l} is not a supported language by PandaGO (default lang)`);
  }

  return l;
};

const paymentOptions = ns('REACT_APP_PAYMENT_OPTIONS')((p: string, E: string) => {
  const po = p.split(',');

  if (po.length === 0) {
    throw new Error(`${E} not set`);
  }

  for (const l of po) {
    if (!isPaymentMethod(l)) {
      throw new Error(`${l} is not a supported payment method by PandaGO`);
    }
  }

  return po;
});

const defaultPaymentMethod = (dpm: string) => {
  const po = paymentOptions;

  if (!po.includes(dpm)) {
    throw new Error(`${po} should be in payment options`);
  }

  return dpm;
};

const country = (c: string) => {
  if (!isCountry(c)) {
    throw new Error(`${c} is not a supported country by PandaGO`);
  }

  return c as Country;
};

const whatMaps = (wm: string, E: string) => {
  const options = ['GOOGLE', 'TPL'];

  if (!options.includes(wm)) {
    throw new Error(`${E} should be either ${options.join('or ')}`);
  }

  return wm;
};

const dhEntity = (dhe: string, E: string) => {
  const options = ['FOODORA', 'TALABAT', 'FOODPANDA', 'OTLOB'];

  if (!options.includes(dhe)) {
    throw new Error(`${E} should be either ${options.join('or ')}`);
  }

  return dhe;
};

const API = (url: string, E: string) => {
  const REGEX_classicAPI = /^https:\/\/[\w]+\.execute-api\.[\w-]+.amazonaws.com\/(dev|stg|prd)\/api\/v1$/;
  const REGEX_ddosProtectedAPI1 = /^https:\/\/(.*).deliveryhero\.io\/(.*)\/api\/v1$/;
  const REGEX_ddosProtectedAPI2 = /^https:\/\/(.*).ondemandrider\.net\/(.*)\/api\/v1$/;

  if (!url.match(REGEX_classicAPI) && !url.match(REGEX_ddosProtectedAPI1) && !url.match(REGEX_ddosProtectedAPI2)) {
    throw new Error(`${E} is not a valid URL for the API`);
  }

  return url;
};

const WSAPI = (url: string, E: string) => {
  const REGEX_WS = /^wss:\/\/[\w]+\.execute-api\.(.*).amazonaws\.com\/(dev|stg|prd)$/;

  // @ts-ignore
  window.url = url;

  // @ts-ignore
  window.regex = REGEX_WS;

  if (!url.match(REGEX_WS)) {
    throw new Error(`${E} (${url}) is not a valid websocket URL`);
  }

  return url;
};

const TPLKey = () => {
  const E = 'REACT_APP_TPL_KEY';
  const k = process.env[E];
  const wm = ns('REACT_APP_WHAT_MAPS')(whatMaps);

  if (wm !== 'TPL' && k) {
    throw new Error(`${E} should not be set if the country doesnt handle TPL`);
  }

  if (wm === 'TPL' && !k) {
    throw new Error(`${E} should be set if the country handle TPL`);
  }

  return k;
};

const fpEnv = (e: string, E: string) => {
  if (!['dev', 'stg', 'prd'].includes(e)) {
    throw new Error(`${E} should be dev, stg or prd`);
  }

  return e;
};

const release = () => {
  const c = process.env.REACT_APP_RELEASE || '';

  if (process.env.REACT_APP_LOCAL_SERVER) return 'local-server';

  return c;
};

const isLocalServer = () => {
  const ls = process.env.REACT_APP_LOCAL_SERVER || '';

  if (ls) return true;

  return false;
};

export default {
  country: ns('REACT_APP_COUNTRY')(country) as Country,
  currency: ns('REACT_APP_CURRENCY')(),
  supportedLanguages: ns('REACT_APP_SUPPORTED_LANG')(supportedLanguages),
  defaultLang: ns('REACT_APP_DEFAULT_LANGUAGE')(defaultLang),
  paymentOptions,
  defaultPaymentMethod: ns('REACT_APP_DEFAULT_PAYMENT_METHOD')(defaultPaymentMethod) as PaymentMethod,
  whatMaps: ns('REACT_APP_WHAT_MAPS')(whatMaps),
  dhEntity: ns('REACT_APP_DH_ENTITY')(dhEntity),
  timezone: ns('REACT_APP_TIMEZONE')(),
  timezoneIdentifier: ns('REACT_APP_TIMEZONE_IDENTIFIER')(),
  api: {
    auth: ns('REACT_APP_FP_ODR_API_URL')(API),
    order: ns('REACT_APP_FP_ODR_ORDER_API')(API),
    hurrier: ns('REACT_APP_FP_ODR_HURRIER_API')(API),
    ws: ns('REACT_APP_FP_ODR_WS_API')(WSAPI),
  },
  googleKey: ns('REACT_APP_GOOGLE_KEY')(),
  TPLKey: TPLKey(),
  fpEnv: ns('REACT_APP_FP_ENV')(fpEnv),
  release: release(),
  isLocalServer: isLocalServer(),
};
