export enum NotifAction {
  Destroy = 'DESTROY_NOTIF',
  Create = 'CREATE_NOTIF',
}

export enum NotifType {
  ERROR = 1,
  SUCCESS,
  WARNING,
  INFO,
}

export type NotifState = {
  items: Notif[];
};

export type CreateNotifActionPayload = {
  // ERROR, SUCCESS, INFO - it will change the background of the Notif
  type: NotifType;
  // content of the Notif
  message: string | JSX.Element;
  // duration in ms
  duration?: number;
};
export type CreateNotifAction = {
  type: typeof NotifAction.Create;
  payload: CreateNotifActionPayload;
};

// destroy Notif
export type DestroyNotifActionPayload = {
  globalID: number;
};
export type DestroyNotifAction = {
  type: typeof NotifAction.Destroy;
  payload: DestroyNotifActionPayload;
};

export type NotifActionTypes = DestroyNotifAction | CreateNotifAction;

export type Notif = CreateNotifActionPayload & DestroyNotifActionPayload;
