// domain
import env from 'src/domains/env';
// local
import { Action, ActionTypes } from './types';

export const supportedLanguages = env.supportedLanguages;

const i18n = (state = { lang: env.defaultLang }, action: Action) => {
  switch (action.type) {
    case ActionTypes.change:
      const lang = action.payload;

      const nextState = {
        lang: supportedLanguages.includes(lang) ? lang : env.defaultLang,
      };

      // window.location.reload();

      return nextState;
    default:
      return state;
  }
};

export default i18n;
