import { applyMiddleware, createStore, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';

// sagas
import ws from './websocket/sagas';
import authSaga from 'src/domains/auth/sagas';
import ordersSaga from 'src/domains/orders/sagas';
import vendorsSaga from 'src/domains/vendors/sagas';
import filtersSaga from 'src/domains/filters/sagas';
import dl from 'src/domains/dl/sagas';

import env from 'src/domains/env';

// reducer
import rootReducer from 'src/domains/reducer';

const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
});

export const middlewares: Middleware[] = [];

if (env.fpEnv === 'devk') {
  middlewares.push(logger);
}

middlewares.push(sagaMiddleware);

export default ({ websocketSaga }: { websocketSaga: boolean }) => {
  let sagas = [...dl, ...authSaga, ...ordersSaga, ...vendorsSaga, ...filtersSaga];

  const store = createStore(rootReducer, applyMiddleware(...middlewares));

  if (websocketSaga) {
    // @ts-ignore
    sagas = [...ws, ...sagas];
  }

  // @ts-ignore
  sagas.forEach(sagaMiddleware.run);

  // @ts-ignore
  const persistor = persistStore(store);
  return { store, persistor };
};
