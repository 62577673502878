import { createSelector } from 'reselect';
import compose from 'lodash/fp/compose';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';

import { IRootState } from 'src/domains/types';
import { Vendor } from 'src/d/pandago';

const getVendors = (state: IRootState) => state.vendors;

export const getVendorsRecords = createSelector(getVendors, vendors => vendors.records);

export const getVendorsSorted = createSelector(
  // retrieve vendors
  getVendorsRecords,
  // with vendors
  vendors =>
    compose(
      // sort them by name
      sortBy((vendor: Vendor) => vendor.vendor_information.name),
      values,
    )(vendors),
);

export const getVendor = (id: string) => createSelector(getVendorsRecords, recordsByID => recordsByID[id] || null);
export const getVendorIsFetching = createSelector(getVendors, vendors => vendors.isFetching);
export const getVendorHasError = createSelector(getVendors, vendors => vendors.error);
