import React from 'react';
import { call, put, race, select, take, takeLatest, delay } from '@redux-saga/core/effects';
import errorTracker from 'src/lib/errorTracker';

// domains
import { createNotification } from 'src/domains/notifications/actions';
import { ActionTypes } from 'src/domains/common/types';
import { NotifType } from 'src/domains/notifications/types';

// local
import { getAuthState, getDelayBeforeExpiration, getTokens, isAuthenticated } from './selectors';
import { AuthServiceResponseResult, Credentials, Tokens, AuthAction, AuthState } from './types';
import { loginSuccess, loginFailure, logout } from './actions';
import authService from './services';
import { dlEvent } from '../dl/actions';
import { EventName } from '../dl/events/names';

// authorize saga is describing the attempt to login with certain credentials
export function* authorize(credentials: Credentials) {
  // call the remote authorization service
  const {
    result,
    error,
  }: { result: AuthServiceResponseResult | null; error: { status: number; message: string } } = yield call(
    authService.login,
    credentials,
  );

  // successfully logged in
  if (result) yield put(loginSuccess(result));

  // we failed to log in
  if (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            Failed to login.
            <div>{error.message}</div>
          </>
        ),
      }),
    );
  }

  return result;
}

export function* refreshToken(args: AuthState) {
  // call the remote authorization service
  const response: AuthServiceResponseResult | null = yield call(authService.refreshToken, args);

  if (response) {
    yield put(loginSuccess(response));

    return response.AuthenticationResult;
  }

  return null;
}

// authFlow describe all the lifetime of the user authentication flow
// here is the behavior explained:
//
// <---- wait for sign in attempt                     <---|
// ----> call login endpoint                              |
// if success: <---|                          <---|       |
//                 |---- wait for expired token   |       |
//                 |---- wait for sign out        |       |
//    if expired token: -> refresh token    ------|       |
//    if sign out: ---------------------------------------|
// if failure: -------------------------------------------|
export function* authFlow() {
  yield take(ActionTypes.INIT_APP);

  const authenticated = yield select(isAuthenticated);
  let tokens: Tokens | null = null;

  // we try to refresh user token on app startup
  if (authenticated) {
    const as = getAuthState(yield select());

    errorTracker.identify(as.user);

    tokens = as.meta;

    if (tokens) {
      tokens = yield call(refreshToken, as);
    }
  }

  // the flow can start again indefinitely
  while (true) {
    if (!tokens) {
      // wait for sign in action
      const { payload: credentials } = yield take(AuthAction.loginAttempt);
      yield put(dlEvent(EventName.SignInDetailsSubmitted));

      // try to login with these credentials
      yield call(authorize, credentials);
      // if succeeded the tokens will be in the state
      tokens = yield select(getTokens);

      if (!tokens) yield put(loginFailure());

      const as = getAuthState(yield select());
      errorTracker.identify(as.user);
    }

    // if there is no token we can just start the flow again and wait for a second attempt to sign in
    if (!tokens) continue;

    // let's consider that the user is now signed in because we retrieve his tokens
    let userSignedIn = true;

    while (userSignedIn) {
      // we are looking for one of the following events:
      //  - the token is about to expire
      //  - the user manually signs out
      const tenMinutesInSeconds = 10 * 60;
      const delayBeforeExpiration = yield select(getDelayBeforeExpiration);
      const delayInSeconds = delayBeforeExpiration - tenMinutesInSeconds;

      // avoid calling in infinite loop the refresh token if the computer has a bad time
      if (delayInSeconds < 0) {
        yield put(logout());
        userSignedIn = false;
        tokens = null;
        continue;
      }

      const { expiredToken } = yield race({
        expiredToken: delay(delayInSeconds * 1000), // authorizationResult.ExpiresIn),
        signOut: take(AuthAction.logout),
      });

      // the token has expired, let's attempt to refresh it
      if (expiredToken && tokens) {
        const as = getAuthState(yield select());
        tokens = yield call(refreshToken, as);

        // we failed to refresh the token
        if (!tokens) {
          // we will trigger the logout
          yield put(logout());
        }
      } else {
        if (tokens) {
          const as = getAuthState(yield select());
          yield call(authService.expireToken, as);
        }
        // the user has manually signed out, we can start the flow again
        userSignedIn = false;
        tokens = null;
      }
    }
  }
}

export function* logoutSideEffects() {
  yield put(
    createNotification({
      type: NotifType.INFO,
      message: 'You are now disconnected.',
    }),
  );
}

// side effects of logout:
//  - display notification
export function* logoutSideEffectsSaga() {
  yield takeLatest(AuthAction.logout, logoutSideEffects);
}

export default [authFlow, logoutSideEffectsSaga];
