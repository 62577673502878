import moment from 'moment';

import { AuthState, Action, AuthAction } from './types';

export const initialState: AuthState = { user: null, meta: null, loading: false };

const auth = (state = initialState, action: Action): AuthState => {
  switch (action.type) {
    case AuthAction.loginAttempt:
      const ns = { ...state };
      ns.loading = true;

      return ns;
    case AuthAction.loginSuccess:
      const nextState: AuthState = {
        user: {
          id: action.payload.VendorLoginDetails.user_id,
          email: action.payload.VendorLoginDetails.email,
        },
        meta: {
          AccessToken: action.payload.AuthenticationResult.AccessToken,
          IdToken: action.payload.AuthenticationResult.IdToken,
          RefreshToken: action.payload.AuthenticationResult.RefreshToken,
          Expires: moment().add(action.payload.AuthenticationResult.ExpiresIn, 'seconds').format(),
        },
        loading: false,
      };

      return nextState;
    case AuthAction.loginFailure:
      return initialState;
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default auth;
