import { AuthAction } from 'src/domains/auth/types';
import { updateItem, listItems } from 'src/domains/helpers/reducer';

import { Action, VendorAction, VendorState } from './types';

export const initialState: VendorState = {
  records: {},
  error: false,
  hasFetched: false,
  isFetching: false,
  lastFetched: null,
};

const vendors = (state = initialState, action: Action): VendorState => {
  switch (action.type) {
    case VendorAction.GetVendor:
      return updateItem(state, action, 'vendor_id');
    case VendorAction.ListVendors:
      return listItems(state, action, 'vendor_id');
    case VendorAction.FetchSuccess:
      return { ...state, isFetching: false, error: false };
    case VendorAction.FetchFailure:
      return { ...state, isFetching: false, error: true };
    case VendorAction.FetchLoading:
      return { ...state, isFetching: true, error: false };
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default vendors;
