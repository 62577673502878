import { ActionType, EventPayload } from './types';
import { EventName } from './events/names';

export const dlEvent = (event: EventName, payload?: EventPayload) => ({
  type: ActionType.Event,
  payload: {
    event,
    ...payload,
  },
});
