// state
export type I18NState = {
  lang: string;
};

// actions
export enum ActionTypes {
  change = 'changeLanguage',
}

export type Change = {
  readonly type: ActionTypes;
  readonly payload: string;
};

export type Action = Change;
