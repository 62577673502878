import errorTracker from 'src/lib/errorTracker';
import w from 'src/lib/window';

import moment from 'moment';

const LS_NAME = 'FP_MAP_VENDOR_FALLBACK_EXPIRATION';

const fallback = (context: string, error?: Error) => {
  // @ts-ignore
  window.__setMapVendor('google');
  localStorage.setItem(LS_NAME, moment().add(1, 'hour').toISOString());

  console.log('error', error);
  // replace by new DD or new relic?
  errorTracker.track(`fallback on google maps while doing ${context}`);

  w.reload();
};

export default fallback;
