import { createMuiTheme } from '@material-ui/core/styles';

import env from 'src/domains/env';

const getPrimaryColor = () => {
  if (env.dhEntity === 'TALABAT') return '#FF5A00'; // orange
  if (env.dhEntity === 'OTLOB') return '#ffd500'; // yellow;
  return '#d70f64'; // pink
};

const getSecondaryColor = () => {
  if (env.dhEntity === 'TALABAT') return '#0E4AFF'; // blue
  if (env.dhEntity === 'OTLOB') return '#632a7b'; // purple
  return '#E9ADC7'; // light pink
};

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: getPrimaryColor(),
    },
    secondary: {
      main: getSecondaryColor(),
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        '&:disabled': {
          color: '#FFF',
          backgroundColor: getSecondaryColor(),
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: getPrimaryColor(),
      },
    },
    MuiTab: {
      root: {
        // fontSize: 14,
        height: 51,
        textTransform: 'none',
        '&$selected': {
          color: getPrimaryColor(),
          fontWeight: 'bold',
        },
        '@media screen and (min-width:600px)': {
          fontSize: 14,
        },
      },
    },
    MuiTypography: {
      h6: {
        // fontSize: 16,
      },
    },
    MuiRadio: {
      root: {
        height: 32,
        width: 32,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 16,
      },
      label: {
        // fontSize: 16,
      },
    },
    MuiListItemText: {
      primary: {
        // fontSize: 16,
      },
    },
    MuiNativeSelect: {
      select: {
        // fontSize: 16,
      },
    },
  },
});

export default theme;
