import moment from 'moment';

import { TrackJS } from 'trackjs';
import { MapVendor } from 'src/d/pandago';
import env from 'src/domains/env';
import w from 'src/lib/window';
import Google from './vendors/Google';

const LS_NAME = 'FP_MAP_VENDOR';
const FALLBACK_LS_NAME = 'FP_MAP_VENDOR_FALLBACK_EXPIRATION';

declare global {
  interface Window {
    __setMapVendor: Function;
  }
}

window.__setMapVendor = (vendor: 'google' | 'tpl') => localStorage.setItem(LS_NAME, vendor);

const getVendor = (): MapVendor => {
  if (env.whatMaps === 'GOOGLE') {
    TrackJS.addMetadata('maps', env.whatMaps);
    return Google;
  }

  const TPL: MapVendor = require('./vendors/TPL').default;

  const vendor = localStorage.getItem(LS_NAME);
  const fallbackData = localStorage.getItem(FALLBACK_LS_NAME);

  if (fallbackData) {
    const now = moment();
    const fallback = moment(fallbackData);

    if (now.isAfter(fallback)) {
      localStorage.removeItem(FALLBACK_LS_NAME);
      localStorage.removeItem(LS_NAME);

      w.reload();
    }
  }

  // cookie not set, lets use env
  if (!vendor) {
    if (env.whatMaps === 'GOOGLE') {
      TrackJS.addMetadata('maps', env.whatMaps);
      return Google;
    }
    TrackJS.addMetadata('maps', 'TPL');
    return TPL;
  }

  const googleRE = new RegExp(`^(.*?google).*$`);

  // found google
  if (googleRE.test(vendor)) {
    TrackJS.addMetadata('maps', 'GOOGLE');
    return Google;
  }

  TrackJS.addMetadata('maps', 'TPL');
  return TPL;
};

const vendor: MapVendor = getVendor();

export default vendor;
