import moment from 'moment';

import { IRootState } from 'src/domains/types';
import { AuthState } from './types';

export const isAuthenticated = (state: IRootState) => {
  const userInState = !!state.auth.user;
  const expires = state.auth.meta?.Expires;

  // seems to be logged in at some point
  if (userInState && expires) {
    // he is logged in if it not expires
    return moment().isBefore(moment(expires));
  }
  return false;
};

export const getAuthState = (state: IRootState): AuthState => state.auth;
export const getTokens = (state: IRootState) => state.auth.meta;
export const getDelayBeforeExpiration = (state: IRootState) => {
  const tokens = getTokens(state);

  if (!tokens || !tokens.Expires) {
    throw new Error('Invalid usage of getDelayBeforeExpiration. Tokens should be set.');
  }

  const now = moment();
  const expires = moment(tokens.Expires);
  const difference = expires.diff(now, 'seconds');

  return difference;
};
export const getUser = (state: IRootState) => {
  const as = getAuthState(state);
  return as.user;
};
export const isAuthLoading = (state: IRootState) => state.auth.loading;
