import { Logout } from 'src/domains/auth/types';

export enum FilterAction {
  SetVendor = 'FILTER_SET_VENDOR',
  SetActiveOrPast = 'FILTER_SET_ACTIVE_OR_PAST',
}

export enum ActiveOrPast {
  Active,
  Past,
}

export type FilterState = {
  vendor: string | null;
  activeOrPast: ActiveOrPast;
};

export type SetVendor = {
  type: typeof FilterAction.SetVendor;
  payload: string;
};
export type SetActiveOrPast = {
  type: typeof FilterAction.SetActiveOrPast;
  payload: ActiveOrPast;
};

export type Action = SetVendor | SetActiveOrPast | Logout;
