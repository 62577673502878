import waitForGlobal from 'src/lib/utils/waitForGlobal';

import api from './api';

import { MapVendor } from 'src/d/pandago';

type Coordinates = {
  lat: number;
  lng: number;
};

// init can avoid crashes in case the Google cdn doesnt respond and that way
// we can protect functions before they use global methods
const init = async () => {
  // main script from TPL
  await waitForGlobal('google');
};

// initMap instantiate a map in a div
const initMap = (divID = 'map-atom') => {
  const mapOptions = {
    // zoom: 5,
    center: { lat: -25.344, lng: 131.036 },
    maxZoom: 17,
    disableDefaultUI: true,
  };

  const el = document.getElementById(divID);

  if (!el) {
    throw new Error('map element not mounted on dom');
  }

  const map: google.maps.Map = new window.google.maps.Map(el, mapOptions);

  return map;
};

const marker = ({ lat, lng, icon }: { lat: number; lng: number; icon: string }) => {
  const position = { lat, lng };
  return new window.google.maps.Marker({ position, icon });
};

type FitBoundOptions = {
  padding: number[];
};
const fitBounds = (map: google.maps.Map, bounds: google.maps.LatLng[], options: FitBoundOptions) => {
  const GBounds = new window.google.maps.LatLngBounds();

  for (const bound of bounds) {
    GBounds.extend(bound);
  }

  if (map) {
    map.setZoom(13);
    map.fitBounds(GBounds, options.padding[0]);
  }
};

const ex: MapVendor = {
  __NAME__: 'GOOGLE',
  api,
  init,
  initMap,
  setZoom: () => true,
  isReady: () => true,
  polygon: ({ points }: { points: number[][] }) => {
    const paths = points.map(point => ({ lat: point[1], lng: point[0] }));

    return new window.google.maps.Polygon({
      // strokeColor: '#FF0000',
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: '#64b5f6',
      fillOpacity: 0.2,
      paths,
    });
  },
  marker,
  latLngBounds: (arrayOfCoordinates: Coordinates[]) => {
    const points = arrayOfCoordinates.map(i => new window.google.maps.LatLng(i.lat, i.lng));
    return points;
  },
  addLayerToMap: (layer: google.maps.Marker | google.maps.Polygon, map: google.maps.Map) => {
    layer.setMap(map);
  },
  removeLayerFromMap: (layer: google.maps.Marker | google.maps.Polygon) => layer.setMap(null),
  fitBounds,
  flyToBounds: fitBounds,
  getLayerLatLng: (layer: google.maps.Marker) => {
    const position = layer.getPosition();

    if (!position) return null;

    return {
      lat: position.lat(),
      lng: position.lng(),
    };
  },
  setLayerDraggable: (marker: google.maps.Marker, draggable = true) => {
    if (!marker || !marker.setDraggable) return;

    marker.setDraggable(draggable);
  },
};

export default ex;
