import React from 'react';
import { FormattedMessage } from 'react-intl';

import { all, put, takeLatest, select } from 'redux-saga/effects';

// utils
import get from 'lodash/get';
// api
import vendorServiceAPI from 'src/lib/api/odr';
// domains
import { Vendor } from 'src/d/pandago';
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import { getAuthState } from 'src/domains/auth/selectors';
import { setVendor } from 'src/domains/filters/actions';
import { getSelectedVendorID } from 'src/domains/filters/selectors';

// internal
import { VendorAction, FetchSingleVendor } from './types';
import { vendorFetchLoading, vendorFetchFailure, vendorFetchSuccess, listVendors, getVendor } from './actions';

export function* fetchVendorInfo(action: FetchSingleVendor) {
  // set store in active state
  yield put(vendorFetchLoading());

  const vendorID = action.payload;
  const auth = getAuthState(yield select());

  try {
    const ro = yield vendorServiceAPI.get(`/vendor/${vendorID}`, auth);
    const vendor = ro?.data?.result as Vendor;

    const selectedVendorID = getSelectedVendorID(yield select());
    if (!selectedVendorID) {
      yield put(setVendor(vendor.vendor_id));
    }

    yield put(getVendor(vendor));
  } catch (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        // not the best message because it's in plural
        message: <FormattedMessage id="error.cant-retrieve-vendors" />,
        duration: 10000,
      }),
    );
    yield put(vendorFetchFailure());
  }
}

export function* fetchAllVendors() {
  // set store in active state
  yield put(vendorFetchLoading());

  const auth = getAuthState(yield select());

  try {
    const r = yield vendorServiceAPI.get('/vendors', auth);
    const vendors = get(r, 'data.result.vendors', []) as Vendor[];

    yield put(listVendors(vendors));

    const selectedVendorID = getSelectedVendorID(yield select());
    if (!selectedVendorID) {
      yield put(setVendor(vendors[0]?.vendor_id || null));
    }

    if (vendors.length === 0) {
      yield put(
        createNotification({
          type: NotifType.ERROR,
          message: <FormattedMessage id="error.no-vendors" />,
          duration: 10000,
        }),
      );
    }

    yield put(vendorFetchSuccess());
  } catch (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: <FormattedMessage id="error.cant-retrieve-vendors" />,
        duration: 10000,
      }),
    );
    yield put(vendorFetchFailure());
  }
}

export function* vendorListSaga() {
  yield all([
    takeLatest(VendorAction.FetchSingleVendor, fetchVendorInfo),
    takeLatest(VendorAction.FetchAllVendors, fetchAllVendors),
  ]);
}

export default [vendorListSaga];
