import en from './locale/en.json';
import urPK from './locale/ur_PK.json';
import urPKAlt from './locale/ur.json';
import frCA from './locale/fr_CA.json';
import thTH from './locale/th_TH.json';
import zh_Hant_TW from './locale/zh_Hant_TW.json';
import tl from './locale/tl.json';
import ar from './locale/ar.json';
import ceb from './locale/ceb.json';

export type Translations = { [key: string]: string };
export type Translation = {
  readonly name: string;
  translations: Translations;
  dir: 'rtl' | 'ltr';
};

export const createTranslationsWithEnglishAsDefault = (translation: Translations): Translations => {
  const t: Translations = {};

  Object.keys(en).forEach((key: string) => {
    const fallback: string = (en as Translations)[key];

    t[key] = translation[key] || fallback;
  });

  return t;
};

const translations: { [key: string]: Translation } = {
  en: {
    name: 'en',
    translations: en,
    dir: 'ltr',
  },
  ur: {
    name: 'ur',
    translations: createTranslationsWithEnglishAsDefault(urPK),
    dir: 'rtl',
  },
  ur_alt: {
    name: 'ur',
    translations: createTranslationsWithEnglishAsDefault(urPKAlt),
    dir: 'ltr',
  },
  fr_CA: {
    name: 'fr',
    translations: createTranslationsWithEnglishAsDefault(frCA),
    dir: 'ltr',
  },
  ar: {
    name: 'ar',
    translations: createTranslationsWithEnglishAsDefault(ar),
    dir: 'rtl',
  },
  th_TH: {
    name: 'th',
    translations: createTranslationsWithEnglishAsDefault(thTH),
    dir: 'ltr',
  },
  zh_Hant_TW: {
    name: 'zh',
    translations: createTranslationsWithEnglishAsDefault(zh_Hant_TW),
    dir: 'ltr',
  },
  tl: {
    name: 'tl',
    translations: createTranslationsWithEnglishAsDefault(tl),
    dir: 'ltr',
  },
  ceb: {
    name: 'cb',
    translations: createTranslationsWithEnglishAsDefault(ceb),
    dir: 'ltr',
  },
};

export default translations;
