import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// own reducers
import auth from 'src/domains/auth/reducer';
import orders from 'src/domains/orders/reducer';
import vendors from 'src/domains/vendors/reducer';
import filters from 'src/domains/filters/reducer';
import notifications from 'src/domains/notifications/reducer';
import websocket from 'src/domains/websocket/reducer';
import i18n from 'src/domains/i18n/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'notifications', 'orders', 'vendors', 'filters', 'websocket'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['loading'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  websocket,
  notifications,
  orders,
  vendors,
  filters,
  i18n,
});

export default persistReducer(rootPersistConfig, rootReducer);
