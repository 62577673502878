import React from 'react';
import { IntlProvider } from 'react-intl';

// misc
import ReactAppOutdated from 'src/misc/ReactAppOutdated';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// domains
import { initApp } from 'src/domains/common/actions';
import { getLang } from 'src/domains/i18n/selectors';
import { IRootState } from 'src/domains/types';
import translations, { Translation } from 'src/i18n';
import env from 'src/domains/env';

import Notifications from 'src/organisms/Notifications';

import TPLMapLoader from 'src/lib/map/vendors/TPL/TPLMapLoader';
import Routes from 'src/Routes';

const connector = connect(
  (state: IRootState) => {
    const l = getLang(state);

    // @ts-ignore
    const lang = translations[l || env.defaultLang];

    // @ts-ignore
    document.querySelector('html').dir = lang.dir;
    // @ts-ignore
    document.querySelector('html').lang = lang.name;

    return {
      lang,
    };
  },
  dispatch =>
    bindActionCreators(
      {
        initApp,
      },
      dispatch,
    ),
);

type Props = {
  initApp: Function;
  lang: Translation;
};

class App extends React.Component<Props> {
  componentDidMount(): void {
    this.props.initApp();
  }

  render() {
    const { lang } = this.props;

    return (
      <IntlProvider locale={lang.name} messages={lang.translations} defaultLocale="en">
        <>
          <TPLMapLoader />
          <ReactAppOutdated />
          <Routes />
          <Notifications />
        </>
      </IntlProvider>
    );
  }
}

export default connector(App);
