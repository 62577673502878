import { Logout } from 'src/domains/auth/types';
import { Order } from 'src/d/pandago';

// todo add create order
export enum OrdersAction {
  // reducer actions
  CreateOrder = 'CREATE_ORDER',
  UpdateOrder = 'UPDATE_ORDER',
  GetOrder = 'GET_ORDER',
  ListOrders = 'LIST_ORDER',
  CancelOrder = 'CANCEL_ORDER_2',
  // fetch state
  FetchLoading = 'ORDER_FETCH_LOADING',
  FetchSuccess = 'ORDER_FETCH_SUCCESS',
  FetchFailure = 'ORDER_FETCH_FAILURE',
  // dispatched actions
  FetchSingleOrder = 'FETCH_SINGLE_ORDER',
  FetchActiveDeliveries = 'FETCH_ACTIVE_DELIVERIES_2',
  FetchPastOrders = 'FETCH_PAST_ORDERS_2',
  FetchOrderWithFilters = 'FETCH_ORDER_WITH_FILTERS',
}

export type OrderState = {
  records: {
    [id: string]: Order;
  };
  error: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  lastFetched: string | null;
};

// actions
export type GetOrder = { type: typeof OrdersAction.GetOrder; payload: Order };
export type ListOrders = { type: typeof OrdersAction.ListOrders; payload: Order[] };
export type FetchLoading = { type: typeof OrdersAction.FetchLoading };
export type FetchSuccess = { type: typeof OrdersAction.FetchSuccess };
export type FetchFailure = { type: typeof OrdersAction.FetchFailure };
export type FetchSingleOrder = {
  type: typeof OrdersAction.FetchSingleOrder;
  payload: { vendorID: string; orderID: string };
};
export type FetchActiveDeliveries = { type: typeof OrdersAction.FetchActiveDeliveries };
export type FetchPastOrders = { type: typeof OrdersAction.FetchPastOrders };
export type FetchOrderWithFilters = { type: typeof OrdersAction.FetchOrderWithFilters };
export type CancelOrder = { type: typeof OrdersAction.CancelOrder; payload: { orderID: string; reason: string } };

export type Action =
  | GetOrder
  | ListOrders
  | FetchLoading
  | FetchSuccess
  | FetchFailure
  | FetchSingleOrder
  | FetchActiveDeliveries
  | FetchOrderWithFilters
  | CancelOrder
  | Logout;
