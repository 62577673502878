// dl actions

import { Vendor, Order } from 'src/d/pandago';
import { EventName } from './events/names';

export enum ActionType {
  Event = 'DL_EVENT',
}

export type EventPayload = {
  event: EventName;
  vendor?: Partial<Vendor>;
  order?: Partial<Order>;
  extra?: { [key: string]: string | number };
};
export type Event = {
  type: typeof ActionType.Event;
  payload: EventPayload;
};

export type Action = Event;
