import { NotifAction, DestroyNotifActionPayload, CreateNotifActionPayload } from './types';

export const createNotification = (payload: CreateNotifActionPayload) => ({
  type: NotifAction.Create,
  payload,
});

export const destroyNotification = (payload: DestroyNotifActionPayload) => ({
  type: NotifAction.Destroy,
  payload,
});
