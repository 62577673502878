import { AuthAction } from 'src/domains/auth/types';
import { updateItem, listItems } from 'src/domains/helpers/reducer';
import { OrdersAction, OrderState, Action } from './types';

export const initialState: OrderState = {
  records: {},
  error: false,
  hasFetched: false,
  isFetching: false,
  lastFetched: null,
};

const orders = (state = initialState, action: Action): OrderState => {
  switch (action.type) {
    case OrdersAction.GetOrder:
      return updateItem(state, action, 'order_id');
    case OrdersAction.ListOrders:
      return listItems(state, action, 'order_id');
    case OrdersAction.FetchSuccess:
      return { ...state, isFetching: false };
    case OrdersAction.FetchFailure:
      return { ...state, isFetching: false };
    case OrdersAction.FetchLoading:
      return { ...state, isFetching: true };
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default orders;
