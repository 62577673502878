import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { getNotifications } from 'src/domains/notifications/selectors';
import { destroyNotification as destroyNotificationAction } from 'src/domains/notifications/actions';
import { IRootState } from 'src/domains/types';
import { Notif, NotifType } from 'src/domains/notifications/types';

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

class Notification extends Component<
  {
    destroyNotification: Function;
  } & Notif
> {
  static defaultProps = {
    type: 'success',
    duration: 3500,
  };

  componentDidMount() {
    const that = this;
    setTimeout(() => that.props.destroyNotification({ globalID: that.props.globalID }), this.props.duration);
  }

  onRequestClose = () => {
    const { globalID } = this.props;

    this.props.destroyNotification({ globalID });
  };

  render() {
    const { type, message } = this.props;

    return (
      <Snackbar
        open
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          {type === NotifType.ERROR && (
            <Alert onClose={this.onRequestClose} severity="error" data-cy="notif-error">
              {message || 'An error occurred'}
            </Alert>
          )}
          {type === NotifType.WARNING && (
            <Alert onClose={this.onRequestClose} severity="warning" data-cy="notif-warning">
              {message}
            </Alert>
          )}
          {type === NotifType.INFO && (
            <Alert onClose={this.onRequestClose} severity="info" data-cy="notif-info">
              {message}
            </Alert>
          )}
          {type === NotifType.SUCCESS && (
            <Alert onClose={this.onRequestClose} severity="success" data-cy="notif-success">
              {message || 'Success!'}
            </Alert>
          )}
        </>
      </Snackbar>
    );
  }
}

const Notifications = ({
  destroyNotification,
  notifications,
}: {
  destroyNotification: Function;
  notifications: Notif[];
}) => (
  <div>
    {notifications.map(item => (
      <Notification key={item.globalID} destroyNotification={destroyNotification} {...item} />
    ))}
  </div>
);

Notifications.defaultProps = {
  notifications: [],
};

export default connect(
  (state: IRootState) => ({
    notifications: getNotifications(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        destroyNotification: destroyNotificationAction,
      },
      dispatch,
    ),
)(Notifications);
