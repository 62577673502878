import remove from 'lodash/remove';

import { Notif, NotifAction, NotifState, NotifType, NotifActionTypes } from './types';

export const initialState: NotifState = {
  items: [],
};
let globalID = 1;

const notifications = (state = initialState, action: NotifActionTypes): NotifState => {
  switch (action.type) {
    case NotifAction.Create:
      const a = [...state.items];

      a.push({
        message: action.payload.message,
        type: action.payload.type || NotifType.INFO,
        duration: action.payload.duration || 5000,
        globalID: globalID++,
      });

      return { items: a };
    case NotifAction.Destroy:
      const b = [...state.items];

      remove(b, (i: Notif) => i.globalID === action.payload.globalID);

      return { items: b };
    default:
      return state;
  }
};

export default notifications;
