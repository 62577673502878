export enum EventName {
  EntryPageLoaded = 'entry_page.loaded',
  RegisterStartLoaded = 'register_start.loaded',
  RegisterPasswordLoaded = 'register_password.loaded',
  RegisterConfirmationLoaded = 'register_confirmation.loaded',
  SignInStartLoaded = 'signin_start.loaded',
  RequestLoaded = 'request.loaded',
  RequestAddressLoaded = 'request_address.loaded',
  RequestRejectedLoaded = 'request_rejected.loaded',
  RequestAcceptedLoaded = 'request_accepted.loaded',
  RequestDetailsLoaded = 'request_details.loaded',
  RequestReviewLoaded = 'request_review.loaded',
  RequestPendingLoaded = 'request_pending.loaded',
  RequestConfirmationLoaded = 'request_confirmation.loaded',
  RegisterClicked = 'register.clicked',
  RegisterDetailsSubmitted = 'RegisterDetailsSubmitted',
  RegisterPasswordSubmitted = 'register_password.submitted',
  RegisterLoginClicked = 'register_login.clicked',
  RegisterRejectedLoaded = 'register_rejected.loaded',
  RegisterRejectedClicked = 'register_rejected.clicked',
  SignInClicked = 'signin.clicked',
  SignInDetailsSubmitted = 'signin_details.submitted',
  PasswordResetClicked = 'password_reset.clicked',
  PasswordResetLoaded = 'password_reset.loaded',
  PasswordResetSubmitted = 'password_reset.submitted',
  PasswordResetCompleted = 'password_reset.completed',
  RequestStarted = 'request.started',
  RequestAddressSubmitted = 'request_address.submitted',
  RequestDetailsSubmitted = 'request_details.submitted',
  RequestReviewSubmitted = 'request_review.submitted',
  RequestReviewCancelled = 'request_review.cancelled',
  ActiveDeliveryClicked = 'activedelivery.clicked',
  OrderHistoryClicked = 'orderhistory.clicked',
}
