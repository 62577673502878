// activedelivery.clicked

import { EventName } from './names';
import {
  Event,
  EventProps,
  Page,
  Country,
  Lang,
  Map,
  locationMethod,
  country,
  language,
  currency,
  location,
  vendor,
} from './common';

export default (p: EventProps): Event & Page & Lang & Country & Map => ({
  event: EventName.ActiveDeliveryClicked,
  pageType: 'request',
  ...country(),
  ...language(p.lang),
  ...currency(),
  ...location(p.vendor),
  ...vendor(p.vendor),
  ...locationMethod(),
});
