import env from 'src/domains/env';

import { TrackJS } from 'trackjs';
import { StateUser } from 'src/domains/auth/types';
import log from 'src/lib/utils/log';

const init = () => {
  if (!['prd', 'stg'].includes(env.fpEnv)) {
    log.success('trackjs', `disabled on ${env.fpEnv}`);
    return;
  }
  if (env.isLocalServer) {
    log.success('trackjs', `disabled on local server`);
    return;
  }

  const application = `odr-fe-${env.fpEnv}-${env.country}`;

  log.success('trackjs', application);

  TrackJS.install({
    token: 'c89bbd2861d644e7a4834c3508bd037e',
    application,
    version: env.release,
  });
  TrackJS.addMetadata('version', env.release);
  TrackJS.addMetadata('stage', env.fpEnv);
  TrackJS.addMetadata('country', env.country);
  TrackJS.addMetadata('maps', env.whatMaps);
};

const track = (message: string, metadata?: { [key: string]: string }) => {
  if (!TrackJS.isInstalled()) return;

  if (metadata) {
    Object.keys(metadata).forEach(key => {
      TrackJS.addMetadata(key, metadata[key]);
    });
  }

  TrackJS.track(message);
};

const identify = (user: StateUser | null) => {
  const { id } = user || { id: '' };

  TrackJS.addMetadata('userID', id);
};

export default {
  init,
  track,
  identify,
};
