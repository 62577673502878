// types
import { Logout } from 'src/domains/auth/types';

export enum WSActionType {
  ConnectionClosed = 'WS_CONNECTION_CLOSED',
  ConnectionOpened = 'WS_CONNECTION_OPENED',
  WriteMessage = 'WS_WRITE_MESSAGE',
}

export enum WSStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export type WSState = {
  wsStatus: WSStatus;
  messageQueue: string[];
};

export type WSWriteMessage = { type: typeof WSActionType.WriteMessage; payload: string };
export type WSConnectionClosed = { type: typeof WSActionType.ConnectionClosed };
export type WSConnectionOpened = { type: typeof WSActionType.ConnectionOpened };

export type Action = WSWriteMessage | WSConnectionClosed | WSConnectionOpened | Logout;
