export enum Country {
  Bahrain = 'BH',
  Egypt = 'EG',
  HongKong = 'HK',
  Jordan = 'JO',
  Kuwait = 'KW',
  Malaysia = 'MY',
  Oman = 'OM',
  Philippines = 'PH',
  Pakistan = 'PK',
  Qatar = 'QA',
  Singapore = 'SG',
  Thailand = 'TH',
  Taiwan = 'TW',
  UnitedArabEmirates = 'AE',
}

export const isCountry = (c: string) => Object.values(Country).includes(c as Country);

export default {
  Country,
  isCountry,
};
