// TPL API Helper
//
// Their documentation is actually poor and the SDK is not cool either

import axios from 'axios';
import qs from 'qs';

import env from 'src/domains/env';
import googleFallback from 'src/lib/map/vendors/Google/fallback';

const API_URL = 'https://api1.tplmaps.com:8888';

type Coordinates = {
  latitude: number;
  longitude: number;
};

type CoordinatesShort = {
  lat: number;
  lng: number;
};

type SearchParameters = {
  name?: string;
  limit?: number;
  apikey?: string;
  location?: string;
  point?: string;
  radius?: number;
};

export type TPLSearchResultData = {
  // the complete address
  compound_address_parents: string;
  // some id
  fkey: number;
  // some legit id
  id: number;
  // coordinates: latitude
  lat: number;
  // coordinates: longitude
  lng: number;
  // simple name but often way too simple to understand where it is
  name: string;

  // priority: 3: number (what is this ?)
  // subcat_name: "CLOTHING STORE": string, but what to do with that?
  // type: "POI" same here
};

export type TPLAPI = {
  // returns places from the TPL api
  search: (query: string, locationBias?: Coordinates) => Promise<TPLSearchResultData[]>;
  // returns the name of the place
  reverseGeocoding: (point: CoordinatesShort) => Promise<string>;
};
const auth: (p: object) => SearchParameters = (p: object) => ({ ...p, apikey: env.TPLKey });

// https://en.wikipedia.org/wiki/Haversine_formula
const getDistanceFromLatLonInKM = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  const rlat1 = toRadian(lat1);
  const rlat2 = toRadian(lat2);

  // Haversine Formula
  const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(rlat1) * Math.cos(rlat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  const finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  return finalDistance;
};

const api = {
  search: async (query: string, locationBias?: Coordinates) => {
    console.log('search', query);
    console.log('locationBias', locationBias);

    const params: SearchParameters = auth({
      name: query,
      limit: 10,
    });

    if (locationBias) {
      params.location = `${locationBias.latitude};${locationBias.longitude}`;
      params.radius = 20;
      params.point = `${locationBias.latitude};${locationBias.longitude}`;
    }

    let data: TPLSearchResultData[] = [];

    try {
      const result = await axios.get(`${API_URL}/search?${qs.stringify(params)}`);

      if (!(result.data instanceof Array)) {
        throw new Error('TPL changed their data structure');
      }

      data = result.data as TPLSearchResultData[];
    } catch (err) {
      googleFallback('search', err);
    }

    if (locationBias) {
      // sort by distance
      data.sort(d => getDistanceFromLatLonInKM(d.lat, d.lng, locationBias.latitude, locationBias.longitude));
    }

    return data;
  },
  reverseGeocoding: async (point: CoordinatesShort) => {
    console.log('reverseGeocoding', point);
    const { lat, lng } = point;

    const params = auth({
      point: `${lat};${lng}`,
      limit: 1,
    });

    try {
      const { data } = await axios.get(`${API_URL}/search/rgeocode?${qs.stringify(params)}`);

      if (data[0]) {
        return data[0].compound_address_parents;
      }
    } catch (err) {
      // fallback google
      googleFallback('reverse-geocoding', err);
    }

    return 'UNKNOWN';
  },
};

export default api as TPLAPI;
