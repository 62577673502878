import { Vendor } from 'src/d/pandago';
import { VendorAction } from './types';

export const vendorFetchLoading = () => ({ type: VendorAction.FetchLoading });
export const vendorFetchFailure = () => ({ type: VendorAction.FetchFailure });
export const vendorFetchSuccess = () => ({ type: VendorAction.FetchSuccess });

export const fetchSingleVendor = (vendorID: string) => ({ type: VendorAction.FetchSingleVendor, payload: vendorID });
export const fetchAllVendors = () => ({ type: VendorAction.FetchAllVendors });

export const listVendors = (vendors: Vendor[]) => ({ type: VendorAction.ListVendors, payload: vendors });
export const getVendor = (vendor: Vendor) => ({ type: VendorAction.GetVendor, payload: vendor });
